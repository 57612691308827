
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonShine from '../components/ButtonShine';
import adminPhoto from '../images/admin_photo.jpg';
import format from 'date-fns/format';
import DatePicker from '../components/DatePicker';


export default function NewActu() {
 const [title, setTitle] = useState<string>("");
 const [date, setDate] = useState<string>();
 const [firstDate, setFirstDate] = useState<Date>();
 const [secondDate, setSecondDate] = useState<Date>();
 const [at, setAt] = useState<string>("");
 const [error, setError] = useState<string>("");
 const [details, setDetails] = useState<string>("");
 const [processing, setProcessing] = useState<boolean>(false);
 const [nbDateClick, setNbDateClick] = useState<number>(0);
 const navigate = useNavigate();
 const location = useLocation();
 useEffect(() => { 
  console.log ("firstDate : ", firstDate);
  console.log ("secondDate :", secondDate );
  console.log ("nbClick :", nbDateClick );
})

 const handleSubmit = (e: React.FormEvent) =>{
    e.preventDefault();
    setProcessing(true);
    console.log("form submitted");
    setProcessing(false);
    navigate("/actualites");
 }
 const onInputChange = (e: React.ChangeEvent<HTMLInputElement>)=>{
    if (e.currentTarget.name === "title"){
      setTitle(e.currentTarget.value);
    }else{
      setAt(e.currentTarget.value);
    }
 
 }
 const onDateChange = (e: React.ChangeEvent<HTMLInputElement>)=>{
    console.log("input date change", e.currentTarget.value);
    setNbDateClick(nbDateClick+1);
    
    // si second click, on lance le rangedatepicker
    if(nbDateClick>0){
      setSecondDate(new Date(e.currentTarget.value));     
      setNbDateClick(0);
    }else{
      setFirstDate(new Date(e.currentTarget.value));
    }
 }
 const handleMouseEnter = (e: React.MouseEvent<HTMLTextAreaElement>)=>{
    console.log("enter in textarea", e.currentTarget.value);
 }
 const onTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>)=>{
     setDetails(e.currentTarget.value);
 }
  return (
    <><h2 className='h2-contact'>Créer une nouvelle actualité</h2>
    <form className='contact-form-form' onSubmit={handleSubmit}>
    <div className='contact-form-container'>
      <div className='contact-image-wrapper'>
        <img src={adminPhoto} alt="photo-contact" className='photo-contact'></img>
      </div>
      <div className='contact-form-wrapper'>
        <div className='contact-input-wrapper'>
          <label className='input-label' htmlFor="title">Titre de l'actualité</label>
          <input 
           name="title" 
           type="text" 
           id="title" 
           value={title} 
           placeholder="Titre de l'actu / stage" 
           onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)} 
           className='form-input' 
          >

          </input>
        </div>
        <div className='contact-input-wrapper'>
          <label className='input-label'>date</label>
          <DatePicker type="calendar"/>
        </div>
        <div className='contact-input-wrapper'>
          <label className='input-label' htmlFor='at'>Le lieu </label>
          <input
          className='form-input' 
          name="at" 
          type="text" 
          id="at" 
          value={at} 
          placeholder="Lieu" 
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
          >

          </input>
        </div>
      </div>
    </div>
    <div className='contact-form-wrapper'>
        <div className='contact-input-wrapper'>
        <label className="contact-label" htmlFor="details">{error}</label>
          <textarea 
          className='form-contact-ta' 
          name="details" id="details" cols={30} rows={10}
          value={details}
          onMouseEnter={(e: React.MouseEvent<HTMLTextAreaElement>) => {
            handleMouseEnter(e);
        }}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onTextAreaChange(e)}>
          
          </textarea>
        </div>
        <div className='contact-button-wrapper'>
         <ButtonShine
          disabled={processing}
          position={false}
          type="submit"          
          label={processing ? "..." : "Envoyer"}
          />
        </div>
    </div>
    </form>
    </>
  )
}
