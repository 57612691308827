import React from 'react'


export default function ButtonShine({...props}) {
  return (
    <div className='btnshine-position'>
    <div className="btnshine-container">
    <div className="btnshine-center">
      <button 
      type={props.type ? props.type : "button"}
      onClick={props.onClick}
      className="btnshine">
        <svg id="btnshine-svg" width="180px" height="60px" viewBox="0 0 180 60" className="border">
          <polyline points="179,1 179,59 1,59 1,1 179,1" className="bg-line" />
          <polyline points="179,1 179,59 1,59 1,1 179,1" className="hl-line" />
        </svg>
        <span>{props.label}</span>
      </button>
    </div>
  </div>
  </div>
  )
}
