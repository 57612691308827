import axios, { AxiosInstance } from "axios";



const cancelTokenSource = axios.CancelToken.source();

export class Carticle{
  article_id: number;
  article_title: string;
  title: string;
  at: string;
  category: number;
  dt_start: Date;
  dt_end: Date | null;
  details: string;
  icon: string;
  company: number;
  image: string;
  folder: string;
  toPublish: boolean;
  flyer: string;
  hour?: string;
  option1?: boolean;

  constructor(
      pArticle_id: number, 
      pArticle_title: string,
      pTitle: string,
      pAt: string,
      pCategory: number,
      pDt_start: Date,
      pDt_end: Date | null,
      pDetails: string,
      pIcon: string,
      pCompany: number,
      pImage: string,
      pFolder: string,
      pToPublish: boolean,
      pFlyer: string
   

      ){
          this.article_id = pArticle_id;
          this.article_title= pArticle_title;
          this.title = pTitle;
          this.at = pAt;
          this.category = pCategory;
          this.dt_start = pDt_start;
          this.dt_end = pDt_end;
          this.details = pDetails;
          this.icon = pIcon;
          this.company = pCompany;
          this.image = pImage;
          this.folder = pFolder;   
          this.toPublish = pToPublish;
          this.flyer = pFlyer;
       
  }
}
export class User{
  login?: string;
  password?: string;
  isAuth?: boolean;
  message?: string|undefined;

  public constructor(pLogin: string, pPassword: string, pIsAuth: boolean, pMessage: string){
    this.login = pLogin;
    this.password = pPassword;
    this.isAuth = pIsAuth;
    this.message = pMessage;
  }
 
}

const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit' || 'numeric',
  day: '2-digit' || 'numeric',
};

export default class MyAxiosClass {


private static sentence = "L@ComAnim@leEstMonD@d@!";

private static instance?: AxiosInstance;
private static options = {
  
    AccessControlAllowOrigin : window.location.href, 
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    SameSite : 'None',
    Secure: true,
    withCredentials: true,
    timeout: 10000,
    headers: {
      "Content-Type": "application/json",
      "google-question": "google-question",
      "keyres": "keyres",
      "mailsend": "mailsend" }   
};


//axios.defaults.baseURL = 'http://localhost:5000/api/';
constructor(){  

  axios.defaults.headers.common['Content-Type'] = 'application/json' // for all requests
  axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    
};
 
public static getServer(){
  return MyAxiosClass.setPhpServerLocation();
}
private createAxiosInstance(){  
  axios.defaults.headers.common["Content-Type"] = "application/json" // for all requests
  axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
  
  return axios.create({
    baseURL: MyAxiosClass.setPhpServerLocation(),       
    timeout: 10000, 
    headers: {      
      "Content-Type": "application/json",
      "google-question": "google-question",
      "keyres": "keyres",
      "mailsend": "mailsend"
    },
   
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',  
    cancelToken: cancelTokenSource.token   

});
}

public static getAxiosInstance(){
  
  if (!this.instance) {
    this.instance = new MyAxiosClass().createAxiosInstance();
  }  
  return this.instance;
 
}

private static setPhpServerLocation(){
  const serverLocation = "https://apiebweb.alienshareonline.fr";
  const localLocation = 'http://localhost/SERVEUREBWEB';
  const location = window.location.hostname.search('localhost') === -1 ? serverLocation : localLocation;
  //console.log("server location : ", location);
  return location;
}

static getClientLocation(){
  //console.log("client location : ", window.location.protocol + '//' + window.location.hostname);
  const clientLocation = window.location.hostname;
  const localLocation = 'localhost'; //http://localhost
  const location = window.location.hostname.search('localhost') === -1 ? clientLocation : localLocation;
  console.log ("location : ", location);
  return location;
}

 static handleCancelRequest = (comment: string) => {
    try {
      cancelTokenSource.cancel(comment);
    } catch (error) {
      console.log("cancel token pas passé php");
    }      
  }
  
  static async postUploadedImage (image : any, path: string, label: string, folder: string, extension: string, article: Carticle, suffix: string){   
    const dateFormatedArticle = {...article, dt_start: (await this.formatDateFoDb(article)).startDate, dt_end: (await this.formatDateFoDb(article)).endDate}
    let name ="";
    
   /*  name= "public/images/"+folder+"/" + label + "_" + new Date().getTime() + "."+ extension; */
    name = suffix + label + "_" + new Date().getTime() + "."+ extension;
    const myInstance = MyAxiosClass.getAxiosInstance();
    const options = {
        headers: {
            'Access-Control-Allow-Origin': MyAxiosClass.getClientLocation(),
            "Content-Type": "application/json"
          }
    }
    const sentitem= {clientname: MyAxiosClass.getClientLocation(), companyid: 1, content: image, name: name, article: article, folder: folder}
    console.log ("on envoie : ", sentitem);
    const SERVER = `${MyAxiosClass.setPhpServerLocation()}/uploadingfile`;
    try {
      const result = await myInstance.post(SERVER, {sentitem: {clientname: MyAxiosClass.getClientLocation(), companyid: 1, content: image, name: name, article: article, folder: folder}}, options);
      if (result !== undefined){
        if(result.data.error){
          console.log("c'est l'erreur " , result.data.error);
        }else{
          console.log("retour ok : ", result.data);
          
        }
        return {name: name};
      }
  } catch (error) {
      return {error: error};
  }
   
}
static async getCategories(){
  const myInstance = MyAxiosClass.getAxiosInstance();
  
  console.log("client location getActivities : ", MyAxiosClass.getClientLocation());
 try {                
   // envoi du nouvel utilisateur en db
   const resp = await myInstance.post(`${this.setPhpServerLocation()}/getcategories`, {sentitem: {clientname: MyAxiosClass.getClientLocation(), companyid: 1}});
   if (resp !== undefined){
    console.log("resp categories not undefined", resp);
     if (resp.data !== undefined)
       { 
           if (resp.data.id === "ok"){                         
             return {categories: resp.data, success: true};
           }else{   
             console.log("cas erreur serveur: ", resp.data.id);
             return {categories: [], success: false};
           }
         }else{ // error
           console.log("erreur php: ", resp.data);
           return ({error: resp.data.libelle});
         }
       }
      
           
     /*GESTION DES ERREURS*/
       }catch(error){
         console.log('erreur post php', error);
        return ({error: error});               
       }
       
       /**
       * FIN POST captcha
       */ 
}
static async createNewArticle(pArticle: Carticle){
  const dateFormatedArticle = {...pArticle, dt_start: (await this.formatDateFoDb(pArticle)).startDate, dt_end: (await this.formatDateFoDb(pArticle)).endDate}
  const myInstance = MyAxiosClass.getAxiosInstance();
  try {
    const resp = await myInstance.post(`${this.setPhpServerLocation()}/createarticle`, {sentitem:{clientname: MyAxiosClass.getClientLocation(), companyid: 1, article: dateFormatedArticle}});
    if (resp !== undefined){
     
      if (resp.data){
        console.log ("retour api create : " , resp.data);
        if (resp.data.error){          
          return resp.data.error;
          
        }else{
          return {lastinsert: resp.data.lastinsert, success: resp.data.success};
        }
       
      }
   }
   
  } catch (error) {
    return ({error: error, success: false});
  }
 
}
static formatUnits (toFormat: string){
  if(parseInt(toFormat, 10) >=10){
      return toFormat;
  }else{
      return "0"+ toFormat;
  }
}
private static formatDateFoDb(pArticle: Carticle){
// Create a date object from a dt_startdate
  // Get year, month, and day part from the date
  const startYear = pArticle.dt_start.getFullYear();
  const startMonth = pArticle.dt_start.getMonth()+1;
  const startdate = pArticle.dt_start.getDate();
let endYear, endMonth, endDate;

  if (pArticle.dt_end){
   endYear = pArticle.dt_end.getFullYear();
   endMonth = pArticle.dt_end.getMonth()+1;
   endDate = pArticle.dt_end.getDate();
  
}else{
   endYear = pArticle.dt_start.getFullYear();
   endMonth = pArticle.dt_start.getMonth()+1;
   endDate = pArticle.dt_start.getDate(); 
}  
  // Generate yyyy-mm-dd date string
  const formattedStartDate = MyAxiosClass.formatUnits(startYear.toString()) + "-" + MyAxiosClass.formatUnits(startMonth.toString()) + "-" + MyAxiosClass.formatUnits(startdate.toString());
  const formattedEndDate = MyAxiosClass.formatUnits(endYear.toString()) + "-" + MyAxiosClass.formatUnits(endMonth.toString()) + "-" + MyAxiosClass.formatUnits(endDate.toString());
  return {startDate: formattedStartDate, endDate: formattedEndDate}
}

static async updateArticle(pArticle: Carticle){ 

  const dateFormatedArticle = {...pArticle, dt_start: (this.formatDateFoDb(pArticle)).startDate, dt_end: (this.formatDateFoDb(pArticle)).endDate}
  const myInstance = MyAxiosClass.getAxiosInstance();
  console.log("on envoie update : ", dateFormatedArticle);
  try {
    /**Clean path to send only image file */
    const resp = await myInstance.post(`${this.setPhpServerLocation()}/updatearticle`, {sentitem:{clientname: MyAxiosClass.getClientLocation(), companyid: 1, article: dateFormatedArticle}});
    if (resp !== undefined){
      console.log ("update article : ", resp);
      
      if (resp.data){
        if (resp.data.error){
          return {error: resp.data.error, success: false};
        // insert ok
        }else{
          return {success: true};
        }
       
      }
   }
   
  } catch (error) {
    return ({error: error, success: false});
  }
 
}

static async updateArticleWithDefImage(pArticle: Carticle){ 

  const dateFormatedArticle = {...pArticle, dt_start: (this.formatDateFoDb(pArticle)).startDate, dt_end: (this.formatDateFoDb(pArticle)).endDate}
  const myInstance = MyAxiosClass.getAxiosInstance();
  console.log("on envoie update : ", dateFormatedArticle);
  try {
    /**Clean path to send only image file */
    const resp = await myInstance.post(`${this.setPhpServerLocation()}/updatearticlewithdefimage`, {sentitem:{clientname: MyAxiosClass.getClientLocation(), article: dateFormatedArticle}});
    if (resp !== undefined){
      console.log ("update article with def image: ", resp);
      
      if (resp.data){
        if (resp.data.error){
          return {error: resp.data.error, success: false};
        // update ok
        }else{
          return {success: true};
        }
       
      }
   }
   
  } catch (error) {
    return ({error: error, success: false});
  }
 
}

static async deleteArticle(pArticleId: number){
  
  const myInstance = MyAxiosClass.getAxiosInstance();
  try {
    const resp = await myInstance.post(`${this.setPhpServerLocation()}/deletearticle`, {sentitem:{clientname: MyAxiosClass.getClientLocation(), companyid: 1, articleid: pArticleId}});
    if (resp !== undefined){
      console.log(" delete article from db : ", resp);
      if (resp.data){       
        if (resp.data.error){
          return {error: resp.data.error, success: false};
        }else{
          return {success: true};
        }
       
      }
   }
   
  } catch (error) {
    return ({error: error, success: false});
  }
 
}
static async getArticles (){  
  const myInstance = MyAxiosClass.getAxiosInstance();
  
  console.log("client location getActivities : ", MyAxiosClass.getClientLocation());
 try {          
   
   const resp = await myInstance.post(`${this.setPhpServerLocation()}/getadminarticles`, {sentitem: {clientname: MyAxiosClass.getClientLocation(), companyid: 1}});
   if (resp !== undefined){
    console.log("resp articles not undefined", resp);
     if (resp.data !== undefined)
       { 
           if (resp.data.id === "ok"){                         
             return {articles: resp.data, success: true};
           }else{   
             console.log("cas erreur serveur: ", resp.data.id);
             return {articles: [], success: false};
           }
         }else{ // error
           console.log("erreur php: ", resp.data);
           return ({error: resp.data.libelle});
         }
       }
      
           
     /*GESTION DES ERREURS*/
       }catch(error){
         console.log('erreur post php', error);
        return ({error: error});               
       }
       
       /**
       * FIN POST
       */ 
}
static async login(user: User){
  const clientName = MyAxiosClass.getClientLocation();
 
  try {    
    const resp = await MyAxiosClass.getAxiosInstance().post(`${this.setPhpServerLocation()}/login`, {sentitem: {clientname: clientName, companyid: 1, login: user.login, password: user.password, sentence: MyAxiosClass.sentence}});
    if (resp !== undefined){
      
      console.log("rep not undefined : ", resp);
        if (resp.data){          
          if (resp.data === true){ // true auth ok
              console.log("true");
              return {...user, isAuth: true};
          }else{ // false and messages;
            return {message: resp.data};
          }
        }
    }
  } catch (error) {
    console.log(error);
    return ({message: "Une erreur s'est produite, merci de consulter l'administrateur du site"});
  }
 
}

}