
import React, { useEffect, useState } from 'react'
import MyAxiosClass, { User } from '../api/phpApi';
import { useLocation, useNavigate } from 'react-router-dom';
import '../style/AdminLogin.css';


export default function Login({...props}) {
    const navigate = useNavigate();
    const location = useLocation();
    
    const [user, setUser] = useState<User>(new User("", "", false, ""));
    const [message, setMessage] = useState<string>("");
    const options = {state: {user:user, comeFrom:"login"}};
   

    useEffect(() => {   
      if (user.isAuth){        
        navigate('/actualites', options);
      }else{ 
        setMessage("Merci de s'authentifier avant toute modification");
      }
     
    }, [])
    
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>{  
            const {value} = e.currentTarget;
            setUser({...user, login: value}); 
            console.log("user : ", user);
            setMessage("");
    }
    const handleRep = async (rep: User)=>{
        if(rep !== undefined){              
            if (rep.message){                
                setMessage(rep.message);
                setUser({...user, isAuth: false});
            }else{ // true auth ok
                console.log("auth ok");
                setUser({...user, isAuth: true});
                navigate('/actualites', options);
                setMessage("");
            }
    }else{
        setMessage("Chargement en cours");
    }
    }
    const handlePointerEnter=()=>{
        setMessage("");
        
    }
    const handlePassWordChange = (e: React.ChangeEvent<HTMLInputElement>) =>{
        const {value} = e.currentTarget;
        setMessage("");
        setUser({...user, password: value}); 
    }
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>)=>{
        e.preventDefault();
        console.log("handlesubmit ok");
        const resp = await MyAxiosClass.login(user);
        if (resp !== undefined){
            handleRep(resp);
        }
    }

  return (
    <div className='login-form-wrapper'>
     <form className='login-form' onSubmit={handleSubmit}>
        <div className='inputs-wrapper'>
         <div className='input-wrapper'>
            <label className='login-label'>
                Login
            </label>
            <input 
            value={user.login}
            onChange={handleInputChange}
            onPointerEnter={handlePointerEnter} 
            type="text" 
            className='login-input'/>
        </div>
        <div className='input-wrapper'>
            <label className='login-label'>
                Mot de passe
            </label>
            <input 
            type="password" 
            value={user.password}
            className='login-input'
            onPointerEnter={handlePointerEnter}
            onChange={handlePassWordChange}/>
        </div>
        <div className='input-wrapper'>
        <button 
        type='submit' 
        className='login-button'>
            Login
        </button>
        </div>
        <p>
        {message && message !== "" && <span className='login-message'>{message}</span>}
        </p>
        </div>
     </form>
    </div>
  )
}

