import React from 'react';
import logo from './logo.svg';
import './style/App.css';
import {BrowserRouter} from 'react-router-dom';
import Site from './pages/Site';
import TheContextProvider from './context/AdminContext';

declare global {
  interface Window {
    __REACT_DEVTOOLS_GLOBAL_HOOK__: any;
  }
}
function App() {
  
  const disableReactDevTools=()=>{       
    if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object") {
      for (let [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value == "function" ? ()=>{} : null;
      }
    }
  }
  const disableConsoleLog=()=>{
    console.log = function () {};
  }

if (!window.location.href.includes("http://localhost")){
    disableReactDevTools();
    disableConsoleLog();
} 
  return (
   
     <BrowserRouter>
      <TheContextProvider>         
          <Site/>
      </TheContextProvider>
     </BrowserRouter>
  
  );
}

export default App;
