import React, { Component } from 'react'

interface IparentProps{
    children: React.ReactNode
}

const defaultState = {    
    reloadData: false,
    setReloadData: (pState: boolean)=>{},
    
}
const TheContext = React.createContext(defaultState);

export default class TheContextProvider extends Component<IparentProps> {
    constructor(props: IparentProps){
        super(props);
    }
    /**
     * reload or not
     */
    state={
        reloadData: false
    }

    setReloadData=(pReload: boolean)=>{
        this.setState({reloadData: pReload});
       
    }
   

  render() {
    return (
        <TheContext.Provider value={{
            ...this.state, 
            setReloadData: this.setReloadData
          
        }}>
        
        {this.props.children}
        </TheContext.Provider>
    );
  }
}
const TheContextConsumer = TheContext.Consumer;
export function withContext(Component: any){
    return function ConsumerWrapper(props: any){
        return <TheContextConsumer>
            {value=> <Component {...props} context={value}/>}
        </TheContextConsumer>
}}
export {TheContextProvider, TheContextConsumer, TheContext};