import axios from 'axios';
import React, { ChangeEvent, useContext, useRef, useState } from 'react';
import ebLogo from "../svg/ateliers.svg";
import MyAxiosClass from "../api/phpApi";
import { TheContext } from '../context/AdminContext';
import { image } from 'html2canvas/dist/types/css/types/image';


interface PriceI{
    id: number,
    title: string,
    category: string,
    label: string
}
interface DescriptionI{
    id: number,
    category: string,
    title: string,
    infos: string,
    price: PriceI[]
}

export default function UploadForm({...props}) {
    const {setLoadedImage, loadedImage, logoPath, setLogoPath} = props;
    const [isDragActiv, setIsDragActiv] = useState<boolean>(false);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const theContext = useContext(TheContext);

    function setCategoryLabel(id: number){
        let label="";
        switch (id) {
            case 1:
            label = "com"
            break;
            case 2:
            label = "agilité"
            break;
            case 3:
            label = "trec"
            break;
            case 4:
            label = "dressage"
            break;
            case 5:
            label = "cso"
            break;
            default:
                break;
        }
        return label;
    }
    const handleFileUpload=(e: any, id: number)=>{
        const theLabel = setCategoryLabel(id);
        e.preventDefault();
        let value = URL.createObjectURL(e.target.files[0]);
        setLogoPath(value);
        console.log("uploaded : ", value);
        const files = e.target.files;
        if (files){
            let fileReader = new FileReader();
            fileReader.readAsDataURL(e.target.files[0]);
            fileReader.onload = async (event: any) =>{
                // get the extension 
                let extension = event.target.result.split('/')[1].split(';')[0]; //file extension from input file                
                // image path clean before sending to server
                let image = props.article.image;
                if  (props.article.image.includes('http')){
                    const truncTab = props.article.image.split('/');
                    image = truncTab[truncTab.length-1];                   
                }
                var im = new Image();
                im.src = event.target.result;
                im.onload = async function() {
                    setLoadedImage(event.target.result);                    
                    // to keep only last one
                    const suffix = "_temp";
                    console.log("result width : ", im.width);
                    const resizedImage = resizeImage(im);
                    if (resizedImage){
                        setLoadedImage(resizedImage);  
                        const result = await MyAxiosClass.postUploadedImage(resizedImage, image, theLabel, props.article.folder, extension, props.article, suffix);
                        if (result !== undefined){
                            if (result.name){
                                console.log("result upload : ", result.name);
                                console.log("props : ", props);
                                props.updateArticleImage(props.article, result.name.toString());
                                theContext.setReloadData(true);
                            }else{
                                console.log("result error : ", result.error);
                                theContext.setReloadData(false);
                            }
                        }
                    }
                }
            }
        }     
    }
    const handleDrag = (e: React.DragEvent) =>{
        e.preventDefault();
        e.stopPropagation();
        if(e.type === "dragenter" || e.type === "dragover"){
            setIsDragActiv(true);
        }else if (e.type === "dragleave"){
            setIsDragActiv(false);
        }
     
    }
    function calculateImageDimensions(dimension: number, ratio: number){ 
     return Math.round((ratio*dimension)/100);        
    }

    function resizeImage(uploadedImage: any){
        let img = document.createElement("img");
        img = uploadedImage;         
        if (canvasRef.current){
            let context = canvasRef.current.getContext("2d");
            if (context){
                context.drawImage(img, 0, 0);
                // déclarations / initialisations
                let width, height;
                width = uploadedImage.width;
                height = uploadedImage.height;
                if (uploadedImage.width > 800){
                    if (uploadedImage.width < 1000){
                        width = calculateImageDimensions(uploadedImage.width, 75);
                        height = calculateImageDimensions(uploadedImage.height, 75);
                    }else if(uploadedImage.width < 2000){
                        width = calculateImageDimensions(uploadedImage.width, 50);
                        height = calculateImageDimensions(uploadedImage.height, 50);
                    }else{
                        width = calculateImageDimensions(uploadedImage.width, 25);
                        height = calculateImageDimensions(uploadedImage.height, 25);
                    }
                }                
                canvasRef.current.width = width;
                canvasRef.current.height = height;  
                context.drawImage(img, 0, 0, width, height);
                let dataUrl = canvasRef.current.toDataURL("image/png");
                return dataUrl;
            }
        }
    }
    const handleDrop = async (e: React.DragEvent, id: number)=>{
        e.preventDefault();
        e.stopPropagation();
        const theLabel = setCategoryLabel(id);
        setIsDragActiv(false);
        // read
        let files = e.dataTransfer.files;
        if(files){
            let fileReader = new FileReader();
            fileReader.readAsDataURL(files[0]);
            fileReader.onload = async (event: any) =>{
                let im = new Image();
                im.src = event.target.result;
                im.onload = async function() {
                setLoadedImage(event.target.result);
                // get the extension 
                let extension = event.target.result.split('/')[1].split(';')[0]; //file extension from input file                
                // image path clean before sending to server
                let image = props.article.image;
                if  (props.article.image.includes('http')){
                    const truncTab = props.article.image.split('/');
                    image = truncTab[truncTab.length-1];                   
                }
                // to keep only last one
                const suffix = "_temp";
                console.log("result width : ", event.target.result.width);
                const resizedImage = resizeImage(im);
                if (resizedImage){
                    setLoadedImage(resizedImage);
                    const result = await MyAxiosClass.postUploadedImage(resizedImage, image, theLabel, props.article.folder, extension, props.article, suffix);
                    if (result !== undefined){
                        if (result.name){
                            console.log("result upload : ", result.name);
                            console.log(" upload article : ", props);
                            props.updateArticleImage(props.article, result.name.toString());
                            theContext.setReloadData(true);                        
                            
                        }else{
                            console.log("result error : ", result.error);
                            theContext.setReloadData(false);
                        }
                    }
                }
                }
            }
        }

        // createObj
        if(e.dataTransfer.files && e.dataTransfer.files[0]){            
            let value = URL.createObjectURL(e.dataTransfer.files[0]);
            setLogoPath(value);           
        }
    }
  return (
        <div className='upload-container'>            
            <div className='upload-wrapper'>
                <div className='file-input-info'>Glisser l'image</div>
                <div className='file-btn-fake'>
                Ou cliquer pour télécharger
            </div>
            <input 
                id={props.article.article_id+'upload' }
                onDragEnter={(e: React.DragEvent)=>handleDrag(e)}
                onDragLeave={(e: React.DragEvent)=>handleDrag(e)}
                onDragOver={(e:React.DragEvent)=>handleDrag(e)}
                onDrop={(e: React.DragEvent)=>handleDrop(e, props.article.category)} 
                onChange={(e: ChangeEvent)=>handleFileUpload(e, props.article.category)} 
                name='upload' 
                className="upload-input" 
                type="file" 
                accept="image/png, image/gif, image/jpeg, image/jpg"
                multiple={false}>
            </input>            
            <img 
            src={logoPath}
            className="upload-logo-img"
            />
            <canvas ref={canvasRef}></canvas>
            </div>
        </div>

    
   
  )
}
