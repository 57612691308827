import React from 'react'
import {Route, Routes} from 'react-router-dom';
import Login from './Login';
import Actualities from './Actualities';
import NewActu from './NewActu';

export default function Site() {

  return (
    <div className='App'>
      <Routes>
        <Route path="/" element={<Login/>}/>
        <Route path="/actualites" element={<Actualities/>}/>
        <Route path="/nouvelle" element={<NewActu/>}/>
      </Routes>
    </div>
  )
}
