


import '../style/FollowPoints.css'

export default function FollowPoints({...props}) {
 
 
  interface pointInter{
   id: number;
   label: string;
 }
  const points = [{id : 1, label: "."}, {id : 2, label: "."}, {id : 3, label: "."}]
  return (
        
          <div className = "points-wrapper">         
              {points.map((aPoint: pointInter)=>(               
               <div className='moving-loading' key={aPoint.id} >{aPoint.label}</div>          
              ))}
             
          </div>
   
  )
}
