import React, { useContext, useEffect, useRef, useState } from 'react'
import MyAxiosClass, { Carticle } from '../api/phpApi';
import DatePicker from './DatePicker';
import UploadForm from './UploadZone';
import {FaHatCowboy, FaHorse} from "react-icons/fa";
import {GiTrophyCup, GiAtSea, GiMeditation, GiFlagObjective,GiTopHat } from "react-icons/gi";
import { TbClockPin, TbHorseToy } from "react-icons/tb";
import { TheContext } from '../context/AdminContext';
import FollowPoints from './FollowPoints';


interface Iarticle{
    article_id: number,
    article_title: string,
    title: string,
    at: string,
    category: number,
    dt_start: Date,
    dt_end: Date | null,
    details: string,
    icon: string
    company: number,
    image: string,
    folder: string
}
interface Icategory{
    id: number,
    title: string
  
}
class Ccategory{
    id: number;
    title: string;
  constructor(pId: number, pTitle: string){
    this.id = pId;
    this.title = pTitle;
  }
}


function Article(props: Carticle) {
    const [categories, setCategories] = useState<Ccategory[]>();
    const [article, setArticle] = useState<Carticle>(props);
    const [openArticle, setOpenArticle] = useState<boolean>(false);
    const [error, setError] = useState<string | any>();
    const [logoPath, setLogoPath] = useState<string | undefined>(article.image);
    const [loadedImage, setLoadedImage] = useState();

    const myContext = useContext(TheContext);
    const timerRef = useRef<any>();
   
      
    const comDefaultImage = 'actu_com.jpg';
    const coachingDefaultImage = 'actu_coaching01.jpeg';
    const footDefaultImage ='actu_pied.jpg';
    const crossDefaultImage ='actu_cross.jpg';
    const trecDefaultImage ='actu_trec.jpg';
    const lgReinsDefaultImage ='actu_lgReins.jpeg';
    const dressDefaultImage ='actu_dress.jpg';
    const outDefaultImage = 'actu_out.jpeg';
   useEffect(()=>{
    
       
    })
    useEffect(()=>{
        async function getCategories(){
            const myRes = await MyAxiosClass.getCategories();
            let loadedCats: Ccategory[];
            if (myRes !== undefined && myRes.categories){
                 loadedCats = Object.values(myRes.categories.data).map((aCat: any)=>{
                    return new Ccategory(parseInt(aCat.id, 10), aCat.title);
                })
                if(loadedCats){
                    setCategories(loadedCats);
                }                
                console.log("cat : ", categories);
            }else{
                console.log("chargement en cours");
            }
        }
        getCategories();
        // creation 
        function updateArticleWithDefaultImage(pCategory: number){
            const updatedImage = setDefaultImagePath(pCategory);
            const updatedArticle = {...article, image: updatedImage};
            console.log("updated article : ", updatedArticle);
            MyAxiosClass.updateArticle(updatedArticle).then(()=>{
                setArticle(updatedArticle);
                myContext.setReloadData(true);
 
           })
           .catch((error: any)=>console.log ("erreur update article : ", error));
        }
        if (!article.image){
            if (props.category)
            updateArticleWithDefaultImage(props.category);
        }
       
        // If no image loaded, load from client
        /* const img = document.getElementById('articleimg');
        img?.addEventListener('error', function handleError() {                     
           
        if (article.image === null || article.image === ""){
            if(props.category)
            updateArticleWithDefaultImage(props.category);
        }}) */

  
    }, [])
const handleOptionClick = (e: React.MouseEvent)=>{
    
    setArticle({...article, option1: !article.option1 ? true : false});

}
function updateArticleImage (article: Carticle, pImage: string){
    if (pImage){
    console.log("nouvel article : ", pImage); 
    let image = pImage;
    if  (image.includes('http')){
        const truncTab = image.split('/');
        image = truncTab[truncTab.length-1];                   
    }
    setArticle({...article, image:  setClientImagePath(image)})
}
}

 
    const handleIconDisplay = (category: number) =>{
    
        switch (category) {
            case 1:
            return <GiMeditation className='bubble-icon'/>;
            break;
            case 2:
            return <GiFlagObjective className='bubble-icon'/>;
            break;
            case 3:
            return <FaHatCowboy className='bubble-icon'/>;
            break;
            case 4:
            return <GiTopHat className='bubble-icon'/>;
            break;
            case 5:
            return <GiTrophyCup className='bubble-icon'/>;
            break;
            case 6:
            return <GiAtSea className='bubble-icon'/>;
            break;
            case 7:
            return <FaHatCowboy className='bubble-icon'/>;
            break;
            case 9:
            return <FaHorse className='bubble-icon'/>;
            break;        
            case 10:
            return <FaHatCowboy className='bubble-icon'/>;
            default:
            break;
        }
    }
    function setCategoryTitle(category: number){
        let title = "";
        switch (category) {
            case 1:
            title = "Communication animale";
            break;
            case 2:
            title = "Equifeel | Equifun";
            break;
            case 3:
            title = "TREC";
            break;
            case 4:
            title = "Dressage";
            break;
            case 5:
            title = "Coaching";
            break;
            case 6:
            title = "Montain train";
            break;
            case 7:
            title = "Cross";
            break;
            case 9:
            title = "Longues rênes";
            break;        
            
            default:
                title = "Longues rênes";
            break;
        }
      
        return title;
    }
    const setDefaultImagePath = (category: number) =>{
     
        const fixPart = `${MyAxiosClass.getServer()}/public/images/${props.folder}/`;
        let deltaPart = "";
        return fixPart+setDefaultServerImagePath(category);
    }
    const setClientImagePath = (pImage: string) =>{
        let cleanImage = pImage;
        if(pImage.includes('http')){
        const cleanPathTab = pImage.split("/");
        cleanImage = cleanPathTab[cleanPathTab.length-1];
        } 
        // reconstuit un path propre    
        const fixPart = `${MyAxiosClass.getServer()}/public/images/${props.folder}/`;
        return fixPart+cleanImage;
    }
    const setDefaultServerImagePath = (category: number) =>{
     
        //const fixPart = `${MyAxiosClass.getServer()}/public/${props.folder}/`;
        let deltaPart = "";
        switch (category) {
            case 1:
            deltaPart = comDefaultImage;
            break;
            case 2:
            deltaPart = footDefaultImage;
            break;
            case 3:
            deltaPart = trecDefaultImage;
            break;
            case 4:
            deltaPart = dressDefaultImage;
            break;
            case 5:
            deltaPart = coachingDefaultImage;
            break;
            case 6:
            deltaPart = outDefaultImage;
            break;
            case 7:
            deltaPart = crossDefaultImage;
            break;
            case 9:
            deltaPart = lgReinsDefaultImage;
            break;        
            case 10:
            deltaPart = crossDefaultImage;
            break; 
            default:
                deltaPart = crossDefaultImage;
            break;
        }
      
        return deltaPart;
    }

    const handleDelClick = async (e: React.MouseEvent<HTMLButtonElement>, id: number)=>{
        e.preventDefault();
        if(e.currentTarget.name.includes("abort") ){
            setOpenArticle(false);
            setArticle(props);
        }else{
            setOpenArticle(false);
            const serverResponse = await MyAxiosClass.deleteArticle(article.article_id);
            if (serverResponse !== undefined){
                console.log("response del : ", serverResponse);
                
                if(serverResponse.error){
                       setError(serverResponse.error);
                }else{
                    //reload articles from db
                    myContext.setReloadData(true);
                    
                    setError("Article supprimé");                   
                    timerRef.current = setTimeout(() => {
                        setError("");
                    }, 1000);
                    myContext.setReloadData(true);
                }
            }
        }
  
        
    }
    const handleModifyClick = async (e: React.MouseEvent<HTMLButtonElement>, id: number)=>{
        e.preventDefault();
        setOpenArticle(openArticle ? false : true); 
        // en mode édition / création
        if (openArticle){           
                setError("Enregistrement en cours");
                // just need to rename temp to def --> must send only path
                // and then rename file on server and reload data
                // format image file to send to db
                // si contient http, trunc image
                let truncImage = article.image;
                if  (article.image.includes('http')){
                    const truncTab = article.image.split('/');
                    truncImage = truncTab[truncTab.length-1];                   
                }
                const updateArticle = {...article, image: truncImage};
                const tempArticle = {...article, image: article.image};
                //update article with new image
                setArticle(tempArticle);
                // format
                console.log("on envoie l'article en db : ", updateArticle);
                const serverResponse = await MyAxiosClass.updateArticleWithDefImage(updateArticle);
                if(serverResponse !== undefined){
                    console.log("response update with image: ", serverResponse);
                   if(!serverResponse.success || serverResponse.error){
                       setError(serverResponse.error);
                    }else{
                        //reload articles from db                        
                        timerRef.current = setTimeout(() => {
                            setError("");
                        }, 1000);
                        // reload data to point on renamed file
                        myContext.setReloadData(true);
                    }
                
            }
    }
    }
    const checkIfEnter=(e: React.KeyboardEvent)=>{
        if (e.code === "Enter"){
            setArticle({...article, details: article.details+"\n"})
        }
    }
    const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>)=>{     
        setArticle({ ...article, details: e.currentTarget.value });
    }
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, articleId: number)=>{

        if (articleId === props.article_id){
            // remove articleid from event name and keep only name for property
            const eventTable = e.currentTarget.name.split("-");
            const name = eventTable[1];
            console.log("event name ", name);
            setArticle({...article, [name]: e.currentTarget.value});
        }
    }
    function handleDtStartChange (date: Date){
        console.log ("date début : ",date );
        setArticle({...article, dt_start: date});
    }
    function handleDtEndChange (date: Date){
        console.log ("date fin : ",date );
        setArticle({...article, dt_end: date});
    }
const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>)=>{
    const value = e.currentTarget.value;   
    const tempArticle = {...article, toPublish: value === "1" || article.toPublish === true ? false : true}
    setArticle(tempArticle);    
    // ubdate db without waiting to save
    console.log("dans checkboxx change : ",tempArticle);
    console.log("event id : ", e.currentTarget.id);
    // clean image path before sending
    const cleanPathTab = tempArticle.image.split("/");
    const cleanPath = cleanPathTab[cleanPathTab.length-1];
    const sendArticle = {...tempArticle, image: cleanPath};
    if (tempArticle.article_id === parseInt(e.currentTarget.id),10){
     MyAxiosClass.updateArticle(sendArticle).then(()=>{
        myContext.setReloadData(true);
   })
    .catch((error: any)=>{console.log ("erreur update article : ", error)}
    )
}
}
 
   const modifiers = {   
    disabled: (date: Date) => {
        const todayToCompare = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime();
        const dateToCompare = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime();
        return /* todayToCompare > dateToCompare ? true : */ false}, // disable les dates antérieures à aujourd'hui : désactivé pour ajouter l'historique
    highlight: (date: Date) => console.log("highlight : ", date) // nothing highlighted by default
  }
   const datePickerProps = {
    startDate: article.dt_start && new Date(article.dt_start),
    endDate: article.dt_end && new Date(article.dt_end),
    onStartDateChange: handleDtStartChange,
    onEndDateChange: handleDtEndChange,
    name:"rangeDatePicker",
    format:'dd MMM yyyy',
    locale:"fr-fr",
    modifiers: modifiers
}
const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit' || 'short',
    day: '2-digit' || 'numeric',
    weekday: 'long'
};
function updateArticleFromCategory(pCategory: number){
    /*client side: need a full image path*/
    const tempArticle = {...article, category: pCategory, image: setDefaultImagePath(pCategory), title: setCategoryTitle(pCategory)}
    /*serveur side : doesn't need a full path*/
    const updateArticle = {...article, category : pCategory, image: setDefaultServerImagePath(pCategory)}
    // update db with default image
     setArticle(tempArticle);
     console.log("temp article : ", tempArticle);
     MyAxiosClass.updateArticle(updateArticle).then(()=>{            
         myContext.setReloadData(true);
    })
 .catch((error: any)=>{console.log ("erreur update article : ", error)}
 )
}
    const handleSelectChange=(e: React.ChangeEvent<HTMLSelectElement>)=>{
        console.log("value : ", e.currentTarget.value);
        updateArticleFromCategory(parseInt(e.currentTarget.value, 10))
        
    }

    return (
      <div className="article-row" key={props.article_id}>
        <div className='error-wrapper'>
        {error && <div className='follow-container'><FollowPoints/><div className='error-text-wrapper'> {error} </div></div>}
        </div>
        <div className='article-wrapper' key={props.article_id}>
            <div className='title-group'>
                <div className='article-column'>
                    <input
                        id={article.article_id+"-article_title"}
                        type="text"
                        name={article.article_id+"-article_title"}
                        placeholder='Titre du stage'
                        className='article-input title'                        
                        value={article.article_title}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, props.article_id)}
                    ></input>
                    <span className='date-article-title'> du {article && new Date(article.dt_start).toLocaleDateString("fr-fr", options)}</span>
                </div>

                <div className='article-column'>                    
                    <div className='article-btn-wrapper'>
                        <div className='checkbox-wrapper'>
                            <label className='checkbox-label'>Publier</label>
                            <input 
                            type='checkbox' 
                            id= {article.article_id.toString()}
                            name={article.article_id.toString()} 
                            className='checkbox-publish' 
                            value={article.toPublish.toString()}                           
                            checked={article.toPublish}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>)=> handleCheckBoxChange(e)}
                            />
                        </div>
                        <button
                            id={article.article_id === -1 ? "create" : article.article_id+"-update"}
                            className='update-btn'
                            type="button"
                            name={article.article_id === -1 ? "create" : article.article_id+"-update"}
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleModifyClick(e, props.article_id)}>
                            {!openArticle ? "Modifier" : "Enregistrer"}
                        </button>
                        <button
                            id={article.article_id+"-abort"}
                            type="button"
                            className='abort-btn'
                            name={article.article_id+"-abort"}
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleDelClick(e, article.article_id)}>
                             Annuler
                        </button>
                        <button
                            id= {article.article_id+"-delete"}
                            type="button"
                            className='del-btn'
                            name= {article.article_id+"-delete"}
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleDelClick(e, article.article_id)}>
                             Supprimer
                        </button>
                    </div>
                </div>
            </div>
            <div className={openArticle ? 'shown-group' : 'hidden-group'}>
                <div className='article-first'>
                    <div className='article-column'>
                        <div className="articles-headers">Catégorie</div>
                        <div className='article-column-group'>
                            <select
                                className='article-select'
                                key={article.category}
                                value={article.category}
                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => 
                                    handleSelectChange(e)
                                }
                            >
                                {categories && categories.map((aCategory: Icategory) => (
                                    <option
                                        key={aCategory.id}
                                        /* selected={aCategory.id === props.category} */
                                        value={aCategory.id}
                                    >
                                        {aCategory.title}
                                    </option>
                                ))}
                            </select>
                            <div className="articles-icone">
                            {categories && categories.map((aCategory: Icategory) => (
                                    <span key={aCategory.id} className='icon-style'>{aCategory.id.toString() === article.category.toString() && handleIconDisplay(aCategory.id)}</span>
                            ))}
                                
                            </div>
                        </div>
                    </div>
                    <div className='article-column'>
                        <div className="articles-headers">Localisation</div>
                        <input
                            id={article.article_id+"-at"}
                            type="text"
                            name={article.article_id+"-at"}
                            placeholder='Lieu'
                            className='article-input'
                            value={article.at}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, props.article_id)}
                        >
                        </input>
                    </div>
                </div>

                <div className='article-column'>
                    <div className="articles-headers">Description</div>
                    <textarea
                        maxLength={250}
                        wrap="hard"
                        rows={5}
                        cols={50}
                        placeholder='250 caractères max'
                        className='article-details-ta'
                       /*  onKeyUp={(e: React.KeyboardEvent)=>checkIfEnter(e)} */
                        value={article.details ? article.details : ""}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleTextAreaChange(e)}>
                    </textarea>
                </div>

                <div className='article-column'>
                    <div className="articles-headers">Dates</div>
                    <DatePicker
                        {...datePickerProps}>
                    </DatePicker>
                </div>
                <div className='article-first'>
                <div className='article-column'>
                        <div className="articles-headers">Horaire <TbClockPin /></div>
                        <input
                            id={article.article_id+"-hour"}
                            type="text"
                            name={article.article_id+"-hour"}
                            placeholder='Horaire'
                            className='article-input'
                            value={article.hour ? article.hour : ""}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, props.article_id)}
                        >
                        </input>
                </div>
                <div className='article-column'>
                    <button 
                    id={article.article_id + "-foam"}
                    className={article.option1 ? 'horse-option active':'horse-option'}
                    onClick={(e: React.MouseEvent)=>handleOptionClick(e)}
                    
                    >
                    <TbHorseToy className={article.option1 ? 'foam-btn active':'foam-btn'}/>
                    </button>
                </div>
                </div>
                <div className="articles-headers">Image</div>
                <div className='article-first'>
                    <div className="td-image">
                        {article.image && <img id={"articleimg"+ article.article_id} src={setClientImagePath(article.image)} alt={article.title} className="admin-image-table"></img>
                        }</div>
                    <div className="td-image">

                        <UploadForm
                        loadedImage={loadedImage}
                        setLoadedImage = {setLoadedImage}
                        logoPath = {logoPath}
                        setLogoPath = {setLogoPath}
                        updateArticleImage={updateArticleImage}
                        article={article}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

    )
}

export default Article
